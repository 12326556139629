<template>
	<v-app-bar color="primary" dense dark app>
		<v-toolbar-title>Administratörspanel</v-toolbar-title>
		<v-spacer />
		<template v-if="loggedIn">
			<v-btn @click="logout" icon title="Logga ut">
				<v-icon>mdi-lock-open</v-icon>
			</v-btn>
		</template>
		<template v-else>
			<v-btn @click="login" icon title="Logga in">
				<v-icon>mdi-lock</v-icon>
			</v-btn>
		</template>
	</v-app-bar>
</template>

<script>
import { LOGGED_IN } from '@/graphql';

export default {
	apollo: {
		loggedIn: {
			query: LOGGED_IN,
			update: data => data.me.loggedIn,
		},
	},
	methods: {
		home() {
			this.$router.push({ name: 'home' });
		},
		logout() {
			this.$router.push({ name: 'logout' });
		},
		login() {
			this.$router.push({ name: 'login' });
		},
	},
};
</script>

<style scoped></style>
