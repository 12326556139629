<template>
	<v-card>
		<a-login-form />
	</v-card>
</template>

<script>
import ALoginForm from '@/components/LoginForm';

export default {
	components: {
		ALoginForm,
	},
};
</script>

<style></style>
