<template>
	<v-form @submit="submit">
		<v-container class="pa-4">
			<a-form-input>
				<v-text-field
					v-model="email"
					:error-messages="emailErrors"
					:disabled="disabled"
					label="E-postadress"
					type="text"
					required
				/>
			</a-form-input>

			<a-form-input>
				<v-text-field
					v-model="password"
					:type="showPassword ? 'text' : 'password'"
					:error-messages="passwordErrors"
					:disabled="disabled"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
					label="Lösenord"
					required
					counter
				/>
			</a-form-input>
		</v-container>

		<v-card-actions>
			<v-flex grow />
			<v-btn
				:loading="loading"
				:disabled="loading"
				@click="submit"
				type="submit"
				color="secondary"
			>
				Logga in
			</v-btn>
		</v-card-actions>
	</v-form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { LOGIN } from '@/graphql';
import AFormInput from '@/components/FormInput';

export default {
	components: {
		AFormInput,
	},
	props: {
		disabled: {
			type: Boolean,
		},
		prefilledEmail: {
			type: String,
			default: () => '',
		},
	},
	data: () => ({
		email: process.env.NODE_ENV === 'production' ? '' : 'testadmin@test.com',
		password: process.env.NODE_ENV === 'production' ? '' : 'test1',
		loading: false,
		showPassword: false,
		emailMatch: true,
	}),
	computed: {
		emailErrors() {
			const errors = [];
			if (!this.$v.email.$dirty) return errors;
			if (!this.$v.email.required) {
				errors.push('E-postadress krävs');
			}

			if (!this.$v.email.email) {
				errors.push('Måste vara en giltig e-postadress');
			}

			return errors;
		},
		passwordErrors() {
			const errors = [];
			if (!this.$v.password.$dirty) return errors;
			if (!this.$v.password.required) {
				errors.push('Lösenord krävs');
			}

			return errors;
		},
	},
	watch: {
		prefilledEmail(newValue) {
			if (newValue.length > 0) {
				this.email = newValue;
				this.password = '';
			}
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.$v.$touch();
			if (!this.$v.$invalid) this.login();
		},
		async login() {
			const email = this.email.toLowerCase();
			const { password } = this;
			const { loading } = this;
			this.loading = true;

			this.$apollo
				.mutate({
					mutation: LOGIN,
					variables: {
						input: {
							email,
							password,
						},
					},
					update: (store, { data: { loginAdmin } }) => {
						store.writeData({
							data: {
								me: {
									...loginAdmin,
									loggedIn: true,
								},
							},
						});
					},
				})
				.catch(error => {
					this.emailMatch = false;
					this.email = email;
					this.password = password;
					this.loading = loading;
					this.$toast.error(error.graphQLErrors[0].message);
					this.$v.$reset();
				});
		},
	},
	validations: {
		email: {
			required,
			email,
		},
		password: {
			required,
		},
	},
};
</script>
