<template>
	<v-container v-if="!loggedIn">
		<a-login-card v-if="this.$router.currentRoute !== { name: 'login' }" />
	</v-container>
</template>

<script>
import ALoginCard from '@/components/LoginCard';
import { LOGGED_IN } from '@/graphql';

export default {
	components: {
		ALoginCard,
	},
	data: () => ({
		loggedIn: true,
		counter: 0,
	}),
	apollo: {
		loggedIn: {
			query: LOGGED_IN,
			update({ me }) {
				// Using a counter here to only redirect once.
				// It is triggered twice.
				if (me.loggedIn && this.counter === 0) {
					this.counter++;
					this.$router.push({ name: 'home' });
				}

				return me.loggedIn;
			},
		},
	},
};
</script>

<style></style>
