import '@mdi/font/css/materialdesignicons.css'; // eslint-disable-line import/no-unassigned-import
import Vue from 'vue';
import VuetifyToast from 'vuetify-toast-snackbar';
import Vuetify, { VBtn, VIcon, VSnackbar } from 'vuetify/lib';

Vue.use(Vuetify, {
	components: {
		VSnackbar,
		VBtn,
		VIcon,
	},
});

const options = {
	icons: {
		iconfont: 'mdi',
	},
};

const vueObject = new Vuetify(options);
export default vueObject;

Vue.use(VuetifyToast, {
	x: 'right',
	y: 'bottom',
	dismissable: true,
	timeout: 6000,
	showClose: true,
	closeIcon: 'mdi-close',
	$vuetify: vueObject.framework,
});
