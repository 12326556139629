import Vue from 'vue';
import Meta from 'vue-meta';
import Router from 'vue-router';
import { apolloClient, LOGGED_IN } from './graphql';
import Login from './views/Login';

Vue.use(Router);
Vue.use(Meta);

const loggedIn = () => {
	const data = apolloClient.readQuery({ query: LOGGED_IN });
	if (data) {
		return data.me.loggedIn;
	}

	return false;
};

const authenticationGuard = next => {
	const authenticated = loggedIn();
	if (authenticated) {
		next();
	} else {
		next({ name: 'login' });
	}
};

const lazyLoad = view => () => import(`@/views/${view}.vue`);

export default new Router({
	base: '/',
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'login',
			component: Login,
		},
		{
			path: '/home',
			name: 'home',
			component: lazyLoad('Home'),
			beforeEnter(to, from, next) {
				authenticationGuard(next);
			},
		},
		{
			path: '/create-company/',
			name: 'create-company',
			component: lazyLoad('CreateCompany'),
			beforeEnter(to, from, next) {
				authenticationGuard(next);
			},
		},
		{
			path: '/company/:id',
			name: 'company',
			props: true,
			component: lazyLoad('Company'),
			beforeEnter(to, from, next) {
				authenticationGuard(next);
			},
		},
		{
			path: '/logout',
			name: 'logout',
			component: lazyLoad('Logout'),
			async beforeEnter(to, from, next) {
				authenticationGuard(next);
			},
		},
		{
			path: '*',
			beforeEnter: (to, from, next) => {
				next('/');
			},
		},
	],
});
