<template>
	<v-app>
		<a-updater />
		<a-top-nav />
		<v-content>
			<v-container fill-height fluid class="pa-0">
				<transition name="fade-transition" mode="out-in">
					<router-view />
				</transition>
			</v-container>
		</v-content>
		<a-logged-in-checker v-if="$router.currentRoute.name !== 'login'" />
	</v-app>
</template>

<script>
import ATopNav from '@/components/TheTopNav';
import ALoggedInChecker from '@/components/LoggedInChecker';
import AUpdater from '@/components/TheUpdater';

export default {
	name: 'App',
	components: {
		ATopNav,
		ALoggedInChecker,
		AUpdater,
	},
};
</script>

<style scoped></style>
