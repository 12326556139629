import { persistCache } from 'apollo-cache-persist';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VuetifyConfirm from 'vuetify-confirm';
import App from './App';
import './RegisterServiceWorker'; // eslint-disable-line import/no-unassigned-import
import router from './router';
import vuetify from '@/plugins/vuetify';
import { apolloClient, apolloProvider, cache } from '@/graphql';

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VuetifyConfirm, { vuetify });

const startApp = async () => {
	const initData = {
		me: {
			__typename: 'Admin',
			loggedIn: false,
		},
	};
	apolloClient.writeData({ data: initData });
	apolloClient.onResetStore(async () => cache.writeData({ data: initData }));

	await persistCache({
		cache,
		storage: window.localStorage,
	});

	new Vue({
		router,
		vuetify,
		apolloProvider,
		render: h => h(App),
	}).$mount('#app');
};

startApp();
