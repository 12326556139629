export * from './mutations';
export * from './queries';
import localSchema from './localSchema/schema.gql';
import { createProvider } from './vue-apollo';

export const apolloProvider = createProvider({
	httpLinkOptions: {
		credentials: 'include',
		headers: {
			'X-Section': 'admin',
		},
	},
	apollo: { typeDefs: localSchema, resolvers: {} },
});

export const { cache } = apolloProvider.clients.defaultClient;
export const apolloClient = apolloProvider.clients.defaultClient;
